import {
  KrPerLiterDefaultFuelSurchargeUse,
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterMachineFuelSurchargeUse,
  KrPerLiterWorkTypeFuelSurchargeUse,
  Machine,
  MachineUrl,
  PriceGroup,
  PricePercentDefaultFuelSurchargeUse,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentMachineFuelSurchargeUse,
  PricePercentWorkTypeFuelSurchargeUse,
  WorkType,
  WorkTypeUrl,
  emptyPriceGroup,
} from "@co-common-libs/resources";
import {
  ColorPickerDialog,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  getCustomerSettings,
  getKrPerLiterDefaultFuelSurchargeUseArray,
  getKrPerLiterFuelSurchargeSpecificationLookup,
  getKrPerLiterMachineFuelSurchargeUseArray,
  getKrPerLiterWorkTypeFuelSurchargeUseArray,
  getMachineArray,
  getMachineLookup,
  getPriceGroupArray,
  getPriceGroupLookup,
  getPriceItemArray,
  getPriceItemLookup,
  getPricePercentDefaultFuelSurchargeUseArray,
  getPricePercentFuelSurchargeSpecificationLookup,
  getPricePercentMachineFuelSurchargeUseArray,
  getPricePercentWorkTypeFuelSurchargeUseArray,
  getUnitLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Fab,
  FormControlLabel,
} from "@material-ui/core";
import {MachineCreateEditDialog, WorkTypeCreateEditDialog} from "app-components";
import {getVerticalStackingFabstyle, useQueryParameter} from "app-utils";
import {ImportMachinesFab, ImportWorkTypesFab} from "feat-import-resources";
import {instanceURL} from "frontend-global-config";
import {useIntegrationCustomerSettings} from "integration-hooks";
import PlusIcon from "mdi-react/PlusIcon";
import {default as React, useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {MachinesOverview} from "./machines-overview";
import {PriceGroupDialog} from "./pricegroup-dialog";
import {WorkTypesOverview} from "./work-types-overview";

const CARD_FAB_WIDTH = 48;
const CARD_FAB_MARGIN = 16;

function findDefaultFuelSurcharge(
  fuelSurchargeUseArray: readonly KrPerLiterDefaultFuelSurchargeUse[],
  fuelSurchargeSpecificationLookup: (
    url: KrPerLiterFuelSurchargeSpecificationUrl,
  ) => KrPerLiterFuelSurchargeSpecification | undefined,
): string | null;
function findDefaultFuelSurcharge(
  fuelSurchargeUseArray: readonly PricePercentDefaultFuelSurchargeUse[],
  fuelSurchargeSpecificationLookup: (
    url: PricePercentFuelSurchargeSpecificationUrl,
  ) => PricePercentFuelSurchargeSpecification | undefined,
): string | null;
function findDefaultFuelSurcharge(
  fuelSurchargeUseArray: readonly (
    | KrPerLiterDefaultFuelSurchargeUse
    | PricePercentDefaultFuelSurchargeUse
  )[],
  fuelSurchargeSpecificationLookup: (
    url: any,
  ) => KrPerLiterFuelSurchargeSpecification | PricePercentFuelSurchargeSpecification | undefined,
): string | null {
  for (const fuelSurchargeUse of fuelSurchargeUseArray) {
    if (
      fuelSurchargeUse.customer ||
      (fuelSurchargeUse.fuelSurcharge &&
        !fuelSurchargeSpecificationLookup(fuelSurchargeUse.fuelSurcharge)?.active)
    ) {
      continue;
    }
    const fuelSurchargeText = fuelSurchargeUse.fuelSurcharge
      ? (fuelSurchargeSpecificationLookup(fuelSurchargeUse.fuelSurcharge)?.name ?? "?")
      : null;
    return fuelSurchargeText;
  }
  return null;
}

function findWorkTypesWithFuelSurcharge(
  workTypeFuelSurchargeUseArray: readonly KrPerLiterWorkTypeFuelSurchargeUse[],
  fuelSurchargeSpecificationLookup: (
    url: KrPerLiterFuelSurchargeSpecificationUrl,
  ) => KrPerLiterFuelSurchargeSpecification | undefined,
): Map<string, string | null>;
function findWorkTypesWithFuelSurcharge(
  workTypeFuelSurchargeUseArray: readonly PricePercentWorkTypeFuelSurchargeUse[],
  fuelSurchargeSpecificationLookup: (
    url: PricePercentFuelSurchargeSpecificationUrl,
  ) => PricePercentFuelSurchargeSpecification | undefined,
): Map<string, string | null>;
function findWorkTypesWithFuelSurcharge(
  workTypeFuelSurchargeUseArray: readonly (
    | KrPerLiterWorkTypeFuelSurchargeUse
    | PricePercentWorkTypeFuelSurchargeUse
  )[],
  fuelSurchargeSpecificationLookup: (
    url: any,
  ) => KrPerLiterFuelSurchargeSpecification | PricePercentFuelSurchargeSpecification | undefined,
): Map<string, string | null> {
  const result = new Map<string, string | null>();
  for (const fuelSurchargeUse of workTypeFuelSurchargeUseArray) {
    if (
      fuelSurchargeUse.customer ||
      (fuelSurchargeUse.fuelSurcharge &&
        !fuelSurchargeSpecificationLookup(fuelSurchargeUse.fuelSurcharge)?.active)
    ) {
      continue;
    }
    const fuelSurchargeText = fuelSurchargeUse.fuelSurcharge
      ? (fuelSurchargeSpecificationLookup(fuelSurchargeUse.fuelSurcharge)?.name ?? "?")
      : null;
    if (fuelSurchargeUse.variant) {
      result.set(`${fuelSurchargeUse.workType}\0${fuelSurchargeUse.variant}`, fuelSurchargeText);
    } else {
      result.set(fuelSurchargeUse.workType, fuelSurchargeText);
    }
  }
  return result;
}

function findMachinesWithFuelSurcharge(
  machineFuelSurchargeUseArray: readonly KrPerLiterMachineFuelSurchargeUse[],
  fuelSurchargeSpecificationLookup: (
    url: KrPerLiterFuelSurchargeSpecificationUrl,
  ) => KrPerLiterFuelSurchargeSpecification | undefined,
): Map<string, string | null>;
function findMachinesWithFuelSurcharge(
  machineFuelSurchargeUseArray: readonly PricePercentMachineFuelSurchargeUse[],
  fuelSurchargeSpecificationLookup: (
    url: PricePercentFuelSurchargeSpecificationUrl,
  ) => PricePercentFuelSurchargeSpecification | undefined,
): Map<string, string | null>;
function findMachinesWithFuelSurcharge(
  machineFuelSurchargeUseArray: readonly (
    | KrPerLiterMachineFuelSurchargeUse
    | PricePercentMachineFuelSurchargeUse
  )[],
  fuelSurchargeSpecificationLookup: (
    url: any,
  ) => KrPerLiterFuelSurchargeSpecification | PricePercentFuelSurchargeSpecification | undefined,
): Map<string, string | null> {
  const result = new Map<string, string | null>();
  for (const fuelSurchargeUse of machineFuelSurchargeUseArray) {
    if (
      fuelSurchargeUse.customer ||
      (fuelSurchargeUse.fuelSurcharge &&
        !fuelSurchargeSpecificationLookup(fuelSurchargeUse.fuelSurcharge)?.active)
    ) {
      continue;
    }
    const fuelSurchargeText = fuelSurchargeUse.fuelSurcharge
      ? (fuelSurchargeSpecificationLookup(fuelSurchargeUse.fuelSurcharge)?.name ?? "?")
      : null;
    if (fuelSurchargeUse.variant) {
      result.set(`${fuelSurchargeUse.machine}\0${fuelSurchargeUse.variant}`, fuelSurchargeText);
    } else {
      result.set(fuelSurchargeUse.machine, fuelSurchargeText);
    }
  }
  return result;
}

export const Overview = React.memo(function OverviewSettingsPage(): JSX.Element {
  const dispatch = useDispatch();
  const showDanglingMachinePriceGroupsAndPriceItems = useQueryParameter(
    "showDanglingMachinePriceGroupsAndPriceItems",
  );
  const showDanglingWorkTypePriceGroupsAndPriceItems = useQueryParameter(
    "showDanglingWorkTypePriceGroupsAndPriceItems",
  );
  const [colorPickerDialogOpenedFor, setColorPickerDialogOpenedFor] = useState<WorkTypeUrl | null>(
    null,
  );
  const [machineDialogOpen, setMachineDialogOpen] = useState<boolean>(false);
  const [priceGroupDialogOpenFor, setPriceGroupDialogOpenFor] = useState<
    MachineUrl | WorkTypeUrl | null
  >(null);
  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState<boolean>(false);

  const intl = useIntl();
  const machineLookup = useSelector(getMachineLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const customerSettings = useSelector(getCustomerSettings);

  const {machines, workTypes} = useIntegrationCustomerSettings();

  const krPerLiterDefaultFuelSurchargeUseArray = useSelector(
    getKrPerLiterDefaultFuelSurchargeUseArray,
  );
  const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
    getKrPerLiterFuelSurchargeSpecificationLookup,
  );
  const krPerLiterMachineFuelSurchargeUseArray = useSelector(
    getKrPerLiterMachineFuelSurchargeUseArray,
  );
  const krPerLiterWorkTypeFuelSurchargeUseArray = useSelector(
    getKrPerLiterWorkTypeFuelSurchargeUseArray,
  );
  const pricePercentDefaultFuelSurchargeUseArray = useSelector(
    getPricePercentDefaultFuelSurchargeUseArray,
  );
  const pricePercentFuelSurchargeSpecificationLookup = useSelector(
    getPricePercentFuelSurchargeSpecificationLookup,
  );
  const pricePercentMachineFuelSurchargeUseArray = useSelector(
    getPricePercentMachineFuelSurchargeUseArray,
  );
  const pricePercentWorkTypeFuelSurchargeUseArray = useSelector(
    getPricePercentWorkTypeFuelSurchargeUseArray,
  );
  const machineArray = useSelector(getMachineArray);
  const priceGroupArray = useSelector(getPriceGroupArray);
  const priceItemArray = useSelector(getPriceItemArray);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);
  const workTypeArray = useSelector(getWorkTypeArray);

  const handleAddMachine = useCallback((): void => {
    setMachineDialogOpen(true);
  }, []);

  const handleAddWorkType = useCallback((): void => {
    setWorkTypeDialogOpen(true);
  }, []);

  const handleWorkTypeDialogCancel = useCallback((): void => {
    setWorkTypeDialogOpen(false);
  }, []);

  const handleMachineDialogCancel = useCallback((): void => {
    setMachineDialogOpen(false);
  }, []);

  const handlePriceGroupDialogOk = useCallback(
    (name: string, identifier: string): void => {
      if (!priceGroupDialogOpenFor) {
        return;
      }
      const id = uuid();
      const url = instanceURL("priceGroup", id);
      const data: PriceGroup = {
        ...emptyPriceGroup,
        active: true,
        id,
        identifier,
        name,
        url,
      };
      dispatch(actions.create(data));
      let machineOrWorkType: Machine | WorkType | undefined;
      if (priceGroupDialogOpenFor.includes("/machines/")) {
        machineOrWorkType = machineLookup(priceGroupDialogOpenFor as MachineUrl);
      } else {
        machineOrWorkType = workTypeLookup(priceGroupDialogOpenFor as WorkTypeUrl);
      }
      const priceGroupURLList = (machineOrWorkType && machineOrWorkType.pricegroups) || [];
      if (!priceGroupURLList.includes(url)) {
        dispatch(
          actions.update(priceGroupDialogOpenFor, [
            {member: "pricegroups", value: [...priceGroupURLList, url]},
          ]),
        );
      }
      setPriceGroupDialogOpenFor(null);
      dispatch(actions.go("/settings/pricegroup/:id", {id}));
    },
    [dispatch, machineLookup, priceGroupDialogOpenFor, workTypeLookup],
  );

  const handlePriceGroupDialogCancel = useCallback((): void => {
    setPriceGroupDialogOpenFor(null);
  }, []);

  const handleAddPriceGroupClick = useCallback((url: MachineUrl | WorkTypeUrl): void => {
    setPriceGroupDialogOpenFor(url);
  }, []);

  const handleChangeColorClick = useCallback((url: MachineUrl | WorkTypeUrl): void => {
    setColorPickerDialogOpenedFor(url as WorkTypeUrl);
  }, []);

  const handleColorPickerDialogCancel = useCallback((): void => {
    setColorPickerDialogOpenedFor(null);
  }, []);

  const handleColorSelected = useCallback(
    (color: string): void => {
      if (colorPickerDialogOpenedFor) {
        dispatch(actions.update(colorPickerDialogOpenedFor, [{member: "color", value: color}]));
      }
      setColorPickerDialogOpenedFor(null);
    },
    [colorPickerDialogOpenedFor, dispatch],
  );

  const handleHideDanglingWorkTypePriceGroupsAndItemsCheck = useCallback(
    (_event: unknown, newValue: boolean): void => {
      dispatch(
        actions.putQueryKey("showDanglingWorkTypePriceGroupsAndPriceItems", newValue ? "1" : ""),
      );
    },
    [dispatch],
  );

  const handleHideDanglingMachinePriceGroupsAndItemsCheck = useCallback(
    (_event: unknown, newValue: boolean): void => {
      dispatch(
        actions.putQueryKey("showDanglingMachinePriceGroupsAndPriceItems", newValue ? "1" : ""),
      );
    },
    [dispatch],
  );

  const {
    canCreateExternalWorkType,
    canCreateMachines,
    economicEnableWorkTypeFromProductGroupImport,
    economicSync,
    enableMachinePriceStructureOverviewSettingsPage,
    enableWorkTypePriceStructureOverviewSettingsPage,
    fuelSurcharge,
    machineLabelVariant,
  } = customerSettings;

  const defaultFuelSurcharge = useMemo((): string | null | undefined => {
    if (fuelSurcharge === "KR_PER_LITER") {
      return findDefaultFuelSurcharge(
        krPerLiterDefaultFuelSurchargeUseArray,
        krPerLiterFuelSurchargeSpecificationLookup,
      );
    } else if (fuelSurcharge === "PRICE_PERCENT") {
      return findDefaultFuelSurcharge(
        pricePercentDefaultFuelSurchargeUseArray,
        pricePercentFuelSurchargeSpecificationLookup,
      );
    }
    return undefined;
  }, [
    fuelSurcharge,
    krPerLiterDefaultFuelSurchargeUseArray,
    krPerLiterFuelSurchargeSpecificationLookup,
    pricePercentDefaultFuelSurchargeUseArray,
    pricePercentFuelSurchargeSpecificationLookup,
  ]);

  const machinesWithSurcharges = useMemo((): Map<string, string | null> | undefined => {
    if (fuelSurcharge === "KR_PER_LITER") {
      return findMachinesWithFuelSurcharge(
        krPerLiterMachineFuelSurchargeUseArray,
        krPerLiterFuelSurchargeSpecificationLookup,
      );
    } else if (fuelSurcharge === "PRICE_PERCENT") {
      return findMachinesWithFuelSurcharge(
        pricePercentMachineFuelSurchargeUseArray,
        pricePercentFuelSurchargeSpecificationLookup,
      );
    }
    return undefined;
  }, [
    fuelSurcharge,
    krPerLiterFuelSurchargeSpecificationLookup,
    krPerLiterMachineFuelSurchargeUseArray,
    pricePercentFuelSurchargeSpecificationLookup,
    pricePercentMachineFuelSurchargeUseArray,
  ]);

  const workTypesWithSurcharges = useMemo((): Map<string, string | null> | undefined => {
    if (fuelSurcharge === "KR_PER_LITER") {
      return findWorkTypesWithFuelSurcharge(
        krPerLiterWorkTypeFuelSurchargeUseArray,
        krPerLiterFuelSurchargeSpecificationLookup,
      );
    } else if (fuelSurcharge === "PRICE_PERCENT") {
      return findWorkTypesWithFuelSurcharge(
        pricePercentWorkTypeFuelSurchargeUseArray,
        pricePercentFuelSurchargeSpecificationLookup,
      );
    }
    return undefined;
  }, [
    fuelSurcharge,
    krPerLiterFuelSurchargeSpecificationLookup,
    krPerLiterWorkTypeFuelSurchargeUseArray,
    pricePercentFuelSurchargeSpecificationLookup,
    pricePercentWorkTypeFuelSurchargeUseArray,
  ]);

  let content: JSX.Element;
  const fabs: JSX.Element[] = [];
  if (
    enableWorkTypePriceStructureOverviewSettingsPage &&
    enableMachinePriceStructureOverviewSettingsPage
  ) {
    const workTypeCardFabs: JSX.Element[] = [];

    if (economicSync && economicEnableWorkTypeFromProductGroupImport) {
      workTypeCardFabs.push(
        <ImportWorkTypesFab
          key="import-work-type-fab"
          buttonStyle={{
            position: "absolute",
            right: (CARD_FAB_WIDTH + CARD_FAB_MARGIN) * workTypeCardFabs.length + CARD_FAB_MARGIN,
            top: 16,
          }}
          size="small"
        />,
      );
    }
    if (canCreateExternalWorkType) {
      workTypeCardFabs.push(
        <Fab
          key="create-work-type-fab"
          size="small"
          style={{
            position: "absolute",
            right: (CARD_FAB_WIDTH + CARD_FAB_MARGIN) * workTypeCardFabs.length + CARD_FAB_MARGIN,
            top: 16,
          }}
          onClick={handleAddWorkType}
        >
          <PlusIcon />
        </Fab>,
      );
    }

    const machineCardFabs: JSX.Element[] = [];

    if (machines.canImport) {
      machineCardFabs.push(
        <ImportMachinesFab
          key="import-machine-fab"
          buttonStyle={{
            position: "absolute",
            right: (CARD_FAB_WIDTH + CARD_FAB_MARGIN) * machineCardFabs.length + CARD_FAB_MARGIN,
            top: 16,
          }}
          size="small"
        />,
      );
    }

    if (canCreateMachines) {
      machineCardFabs.push(
        <Fab
          key="create-machine-fab"
          size="small"
          style={{
            position: "absolute",
            right: (CARD_FAB_WIDTH + CARD_FAB_MARGIN) * machineCardFabs.length + CARD_FAB_MARGIN,
            top: 16,
          }}
          onClick={handleAddMachine}
        >
          <PlusIcon />
        </Fab>,
      );
    }

    content = (
      <>
        <Card style={{marginBottom: 22, minWidth: "fit-content"}}>
          <div style={{position: "relative"}}>{workTypeCardFabs}</div>
          <CardHeader
            title={intl.formatMessage({
              defaultMessage: "Prisoverblik: Områder",
            })}
          />
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!showDanglingWorkTypePriceGroupsAndPriceItems}
                  onChange={handleHideDanglingWorkTypePriceGroupsAndItemsCheck}
                />
              }
              label={intl.formatMessage({
                defaultMessage: "Vis uanvendte varianter og prislinjer",
              })}
              style={{maxWidth: 400}}
            />
          </CardContent>
          <WorkTypesOverview
            customerSettings={customerSettings}
            defaultSurcharge={defaultFuelSurcharge}
            includePriceGroupsNotConnectedToAnyWorkType={
              !!showDanglingWorkTypePriceGroupsAndPriceItems
            }
            includePriceItemsNotConnectedToAnyPriceGroups={
              !!showDanglingWorkTypePriceGroupsAndPriceItems
            }
            priceGroupArray={priceGroupArray}
            priceGroupLookup={priceGroupLookup}
            priceItemArray={priceItemArray}
            priceItemLookup={priceItemLookup}
            unitLookup={unitLookup}
            workTypeArray={workTypeArray}
            workTypeLookup={workTypeLookup}
            workTypesWithSurcharges={workTypesWithSurcharges}
            onAddPriceGroupClick={handleAddPriceGroupClick}
            onChangeColorClick={handleChangeColorClick}
          />
        </Card>
        <Card style={{minWidth: "fit-content"}}>
          <div style={{position: "relative"}}>{machineCardFabs}</div>
          <CardHeader
            title={
              machineLabelVariant === "MACHINE"
                ? intl.formatMessage({
                    defaultMessage: "Prisoverblik: Maskiner",
                  })
                : intl.formatMessage({
                    defaultMessage: "Prisoverblik: Køretøjer",
                  })
            }
          />
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!showDanglingMachinePriceGroupsAndPriceItems}
                  onChange={handleHideDanglingMachinePriceGroupsAndItemsCheck}
                />
              }
              label={intl.formatMessage({
                defaultMessage: "Vis uanvendte varianter og prislinjer",
              })}
              style={{maxWidth: 400}}
            />
          </CardContent>
          <CardMedia>
            <MachinesOverview
              customerSettings={customerSettings}
              defaultSurcharge={defaultFuelSurcharge}
              includePriceGroupsNotConnectedToAnyMachine={
                !!showDanglingMachinePriceGroupsAndPriceItems
              }
              includePriceItemsNotConnectedToAnyPriceGroups={
                !!showDanglingMachinePriceGroupsAndPriceItems
              }
              machineArray={machineArray}
              machineLookup={machineLookup}
              machinesWithSurcharges={machinesWithSurcharges}
              priceGroupArray={priceGroupArray}
              priceGroupLookup={priceGroupLookup}
              priceItemArray={priceItemArray}
              priceItemLookup={priceItemLookup}
              unitLookup={unitLookup}
              onAddPriceGroupClick={handleAddPriceGroupClick}
            />
          </CardMedia>
        </Card>
      </>
    );
  } else if (enableMachinePriceStructureOverviewSettingsPage) {
    content = (
      <>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!showDanglingMachinePriceGroupsAndPriceItems}
              onChange={handleHideDanglingMachinePriceGroupsAndItemsCheck}
            />
          }
          label={intl.formatMessage({
            defaultMessage: "Vis uanvendte varianter og prislinjer",
          })}
          style={{maxWidth: 400, padding: 16}}
        />
        <MachinesOverview
          customerSettings={customerSettings}
          defaultSurcharge={defaultFuelSurcharge}
          includePriceGroupsNotConnectedToAnyMachine={!!showDanglingMachinePriceGroupsAndPriceItems}
          includePriceItemsNotConnectedToAnyPriceGroups={
            !!showDanglingMachinePriceGroupsAndPriceItems
          }
          machineArray={machineArray}
          machineLookup={machineLookup}
          machinesWithSurcharges={machinesWithSurcharges}
          priceGroupArray={priceGroupArray}
          priceGroupLookup={priceGroupLookup}
          priceItemArray={priceItemArray}
          priceItemLookup={priceItemLookup}
          unitLookup={unitLookup}
          onAddPriceGroupClick={handleAddPriceGroupClick}
        />
      </>
    );
    if (canCreateMachines) {
      fabs.push(
        <VerticalStackingFloatingActionButton
          key="create-machine-fab"
          stackIndex={fabs.length}
          onClick={handleAddMachine}
        >
          <PlusIcon />
        </VerticalStackingFloatingActionButton>,
      );
    }
    if (machines.canImport) {
      fabs.push(
        <ImportMachinesFab
          key="import-machine-fab"
          buttonStyle={getVerticalStackingFabstyle(fabs.length)}
        />,
      );
    }
  } else {
    content = (
      <>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!showDanglingWorkTypePriceGroupsAndPriceItems}
              onChange={handleHideDanglingWorkTypePriceGroupsAndItemsCheck}
            />
          }
          label={intl.formatMessage({
            defaultMessage: "Vis uanvendte varianter og prislinjer",
          })}
          style={{maxWidth: 400, padding: 16}}
        />
        <WorkTypesOverview
          customerSettings={customerSettings}
          defaultSurcharge={defaultFuelSurcharge}
          includePriceGroupsNotConnectedToAnyWorkType={
            !!showDanglingWorkTypePriceGroupsAndPriceItems
          }
          includePriceItemsNotConnectedToAnyPriceGroups={
            !!showDanglingWorkTypePriceGroupsAndPriceItems
          }
          priceGroupArray={priceGroupArray}
          priceGroupLookup={priceGroupLookup}
          priceItemArray={priceItemArray}
          priceItemLookup={priceItemLookup}
          unitLookup={unitLookup}
          workTypeArray={workTypeArray}
          workTypeLookup={workTypeLookup}
          workTypesWithSurcharges={workTypesWithSurcharges}
          onAddPriceGroupClick={handleAddPriceGroupClick}
          onChangeColorClick={handleChangeColorClick}
        />
      </>
    );
    if (canCreateExternalWorkType) {
      fabs.push(
        <VerticalStackingFloatingActionButton
          key="create-work-type-fab"
          stackIndex={fabs.length}
          onClick={handleAddWorkType}
        >
          <PlusIcon />
        </VerticalStackingFloatingActionButton>,
      );
    }
    if (workTypes.canImport) {
      fabs.push(
        <ImportWorkTypesFab
          key="import-work-type-fab"
          buttonStyle={getVerticalStackingFabstyle(fabs.length)}
        />,
      );
    }
  }

  const dialogs = [
    <MachineCreateEditDialog
      key="machine-create-edit-dialog"
      open={machineDialogOpen}
      onCancel={handleMachineDialogCancel}
      onOk={handleMachineDialogCancel}
    />,
    <WorkTypeCreateEditDialog
      key="work-type-create-edit-dialog"
      open={workTypeDialogOpen}
      onCancel={handleWorkTypeDialogCancel}
      onOk={handleWorkTypeDialogCancel} // onOk same action as cancel
    />,
  ];

  const colorPickerDialogInitialColor = colorPickerDialogOpenedFor
    ? workTypeLookup(colorPickerDialogOpenedFor)?.color
    : undefined;

  return (
    <>
      {content}
      {fabs}
      {dialogs}
      <PriceGroupDialog
        open={!!priceGroupDialogOpenFor}
        onCancel={handlePriceGroupDialogCancel}
        onOk={handlePriceGroupDialogOk}
      />
      <ColorPickerDialog
        // Change in key, remounts the component and sets new initial value
        key={colorPickerDialogInitialColor || ""}
        initialSelected={colorPickerDialogInitialColor}
        open={!!colorPickerDialogOpenedFor}
        onCancel={handleColorPickerDialogCancel}
        onOk={handleColorSelected}
      />
    </>
  );
});
