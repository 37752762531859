import {Config} from "@co-common-libs/config";
import {
  PatchOperation,
  PathPatchOperation,
  PriceItem,
  PriceItemUrl,
  PriceItemUseWithOrder,
  PriceItemUsesDict,
  Product,
  ProductUrl,
  ProductUseWithOrder,
  ProductUsesDict,
  ReportingInputSpecification,
  ReportingLocations,
  ReportingLog,
  ReportingLogEntry,
  ReportingSpecification,
  ReportingWorkplaceTypeDataSpecification,
  Task,
  TimerUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {
  getInputSpecificationsMap,
  getProductsWithLogData,
  getValue,
  includePriceItemInLogs,
  patchFromProductUsesChange,
  sortProductUseListByCatalogNumber,
} from "@co-common-libs/resources-utils";
import {
  dateToString,
  entriesSortedByOrderMember,
  notUndefined,
  setIntersection,
  valuesSortedByOrderMember,
} from "@co-common-libs/utils";
import {DateField, ResponsiveDialog, TimeField} from "@co-frontend-libs/components";
import {
  actions,
  getContactArray,
  getContactLookup,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getMachineArray,
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getPriceItemArray,
  getPriceItemLookup,
  getProductGroupLookup,
  getProductLookup,
  getProjectLookup,
  getReportingSpecificationLookup,
  getTimerLookup,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {isNoDefaultEnterPress, patchFromPriceItemUsesChange} from "@co-frontend-libs/utils";
import {DialogContent, Grid, IconButton} from "@material-ui/core";
import {EditReportingLocationFab, buildDialogFields, getRelationCountValue} from "app-components";
import {
  buildConversionRelatedValues,
  dateFromDateAndTime,
  getLocationPhone,
  getPotentialTargetTransferPriceItemUrls,
  getPotentialTargetTransferProductUrls,
  getReadonlyProductsFromTask,
  valuesReducer,
} from "app-utils";
import bowser from "bowser";
import {format} from "date-fns";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {MaterialsBlock} from "./materials-block";
import {priceItemUsesReducer, productUsesReducer, wrappedGetLogIssues} from "./utils";

const convertTimestampToTime = (timestamp: string): string => {
  const dateObj = new Date(timestamp);
  return format(dateObj, "HH:mm");
};

function getInitialOrUpdatedPriceItemUses(
  task: Task,
  editingReportingEntry: ReportingLogEntry | undefined,
  valuesFromPrevious: ReadonlyMap<string, number> | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): PriceItemUsesDict {
  const taskPriceItemUses = task.priceItemUses ? valuesSortedByOrderMember(task.priceItemUses) : [];
  const relevantTaskPriceItemUses = taskPriceItemUses.filter((priceItemUse) => {
    const priceItem = priceItemLookup(priceItemUse.priceItem);
    return (
      priceItem &&
      !priceItemUse.dangling &&
      includePriceItemInLogs(unitLookup, taskPriceItemUses, priceItem, priceItemLookup)
    );
  });
  const currentEntries = editingReportingEntry?.priceItemUses
    ? entriesSortedByOrderMember(editingReportingEntry.priceItemUses)
    : [];
  const newEntries = relevantTaskPriceItemUses.map(
    (priceItemUse): [string, PriceItemUseWithOrder] => {
      const matchingExistingEntry = currentEntries.find(
        (currentEntry) => currentEntry[1].priceItem === priceItemUse.priceItem,
      );
      if (matchingExistingEntry) {
        return matchingExistingEntry;
      } else {
        // this price item is not yet present on the entry
        // if editing; use null, if new entry; use computed value
        const count = editingReportingEntry
          ? null
          : (valuesFromPrevious?.get(priceItemUse.priceItem) ?? null);
        return [uuid(), {...priceItemUse, correctedCount: null, count, notes: ""}];
      }
    },
  );
  for (let i = 1; i < newEntries.length; i += 1) {
    const [currentId, currentValue] = newEntries[i];
    const [, previousValue] = newEntries[i - 1];
    if (currentValue.order <= previousValue.order) {
      newEntries[i] = [currentId, {...currentValue, order: previousValue.order + 1}];
    }
  }
  const result = Object.fromEntries(newEntries);
  return result;
}

function getInitialOrUpdatedProductUses(
  task: Task,
  editingReportingEntry: ReportingLogEntry | undefined,
  valuesFromPrevious: ReadonlyMap<string, number> | undefined,
  productLookup: (url: ProductUrl) => Product | undefined,
): ProductUsesDict {
  const taskProductUses = task.productUses ? valuesSortedByOrderMember(task.productUses) : [];
  const newEntries = editingReportingEntry?.productUses
    ? entriesSortedByOrderMember(editingReportingEntry.productUses)
    : [];
  taskProductUses.forEach((taskProductUse) => {
    if (
      !newEntries.some(([_identifier, productUse]) => productUse.product === taskProductUse.product)
    ) {
      // this product is not yet present on the entry
      // if editing; use null, if new entry; use computed value
      const count = editingReportingEntry
        ? null
        : (valuesFromPrevious?.get(taskProductUse.product) ?? null);
      const currentMaxOrder = _.max(newEntries.map((entry) => entry[1].order));
      const nextOrder = currentMaxOrder !== undefined ? currentMaxOrder + 1 : 0;
      newEntries.push([
        uuid(),
        {
          ...taskProductUse,
          correctedCount: null,
          count,
          notes: "",
          order: nextOrder,
        },
      ]);
    }
  });
  const result = Object.fromEntries(newEntries);
  return sortProductUseListByCatalogNumber(result, productLookup);
}

function priceItemProductValuesFromLastLogEntry(
  reportingLog: ReportingLog,
  type: "delivery" | "pickup" | "workplace",
): {
  priceItemValues: Map<string, number>;
  productValues: Map<string, number>;
} {
  const priceItemValues = new Map<string, number>();
  const productValues = new Map<string, number>();
  const lastEntry = _.maxBy(
    Object.values(reportingLog).filter((entry) => entry.type === type),
    (entry) => entry.deviceTimestamp,
  );
  if (lastEntry?.priceItemUses) {
    for (const entry of Object.values(lastEntry.priceItemUses)) {
      if (entry.count != null) {
        priceItemValues.set(entry.priceItem, entry.count);
      }
    }
  }
  if (lastEntry?.productUses) {
    for (const entry of Object.values(lastEntry.productUses)) {
      if (entry.count != null) {
        productValues.set(entry.product, entry.count);
      }
    }
  }
  return {priceItemValues, productValues};
}

function priceItemProductValuesFromRelatedCountValues(
  reportingLog: ReportingLog,
  customerSettings: Config,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  productLookup: (url: ProductUrl) => Product | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  workplaceTypeDataSpecification: ReportingWorkplaceTypeDataSpecification,
  inputSpecificationsMap: Map<string, ReportingInputSpecification>,
  reportingLocations: ReportingLocations,
  valueMaps: {
    readonly [identifier: string]: unknown;
  }[],
): {
  priceItemValues: Map<string, number>;
  productValues: Map<string, number>;
} {
  const priceItemValues = new Map<string, number>();
  const productValues = new Map<string, number>();
  const lastEntry = _.maxBy(
    Object.values(reportingLog).filter((entry) => entry.type === "workplace"),
    (entry) => entry.deviceTimestamp,
  );

  if (!lastEntry) {
    return {priceItemValues, productValues};
  }
  const lastEntryWorkplace =
    lastEntry.location && reportingLocations ? reportingLocations[lastEntry.location] : undefined;
  if (!lastEntryWorkplace) {
    return {priceItemValues, productValues};
  }
  const {priceItemConversion, productConversion} = workplaceTypeDataSpecification;
  const sortedPriceItemUsesWithData = _.sortBy(
    Object.entries(lastEntry.priceItemUses || {})
      .map(([identifier, priceItemUse]) => {
        const priceItem = priceItemLookup(priceItemUse.priceItem);
        if (!priceItem) {
          return undefined;
        }
        const unit = priceItem.relatedUnit ? unitLookup(priceItem.relatedUnit) || null : null;
        return {identifier, priceItem, priceItemUse, unit};
      })
      .filter(notUndefined),
    (entry) => entry.priceItemUse.order,
  );

  if (lastEntry?.priceItemUses) {
    const priceItemConversionDenominatorUnit = priceItemConversion?.unit;
    const priceItemDenominatorValue = priceItemConversion
      ? getValue(
          customerSettings,
          [lastEntry.values, lastEntryWorkplace.values || {}],
          inputSpecificationsMap,
          priceItemConversion.field,
        )
      : undefined;

    const extraConversionRelatedValues =
      priceItemConversionDenominatorUnit && typeof priceItemDenominatorValue === "number"
        ? {[priceItemConversionDenominatorUnit]: priceItemDenominatorValue}
        : undefined;

    const conversionRelatedKeys = Object.keys(
      customerSettings.priceItemRelationUnitConversionHelpers,
    );

    const conversionRelatedValues = buildConversionRelatedValues(
      conversionRelatedKeys,
      sortedPriceItemUsesWithData,
      extraConversionRelatedValues,
    );

    for (const entry of sortedPriceItemUsesWithData) {
      if (entry.priceItemUse.count != null) {
        const relatedCountValue = getRelationCountValue(
          customerSettings.priceItemRelationUnitConversionHelpers,
          conversionRelatedValues,
          customerSettings.materialDecimals,
          entry.unit?.name || "",
          entry.priceItemUse.count,
        );

        if (relatedCountValue) {
          const newDenominator = priceItemConversion
            ? getValue(
                customerSettings,
                valueMaps,
                inputSpecificationsMap,
                priceItemConversion.field,
              )
            : undefined;
          priceItemValues.set(
            entry.priceItemUse.priceItem,
            newDenominator && typeof newDenominator === "number"
              ? relatedCountValue * newDenominator
              : 0,
          );
        }
      }
    }
  }

  if (lastEntry?.productUses) {
    const productConversionDenominatorUnit = productConversion?.unit;
    const productDenominatorValue = productConversion
      ? getValue(
          customerSettings,
          [lastEntry.values, lastEntryWorkplace.values || {}],
          inputSpecificationsMap,
          productConversion.field,
        )
      : undefined;

    const extraConversionRelatedValues =
      productConversionDenominatorUnit && typeof productDenominatorValue === "number"
        ? {[productConversionDenominatorUnit]: productDenominatorValue}
        : undefined;

    const conversionRelatedKeys = Object.keys(
      customerSettings.priceItemRelationUnitConversionHelpers,
    );

    const conversionRelatedValues = buildConversionRelatedValues(
      conversionRelatedKeys,
      sortedPriceItemUsesWithData,
      extraConversionRelatedValues,
    );

    for (const entry of Object.values(lastEntry.productUses)) {
      if (entry.count != null) {
        const product = productLookup(entry.product);
        const relatedCountValue = getRelationCountValue(
          customerSettings.priceItemRelationUnitConversionHelpers,
          conversionRelatedValues,
          customerSettings.materialDecimals,
          product?.relatedUnit ? unitLookup(product.relatedUnit)?.name || "" : "",
          entry.count,
        );

        if (relatedCountValue) {
          const newDenominator = productConversion
            ? getValue(customerSettings, valueMaps, inputSpecificationsMap, productConversion.field)
            : undefined;
          productValues.set(
            entry.product,
            newDenominator && typeof newDenominator === "number"
              ? relatedCountValue * newDenominator
              : 0,
          );
        }
      }
    }
  }
  return {priceItemValues, productValues};
}

function priceItemProductValuesFromPickupDeliveryDifference(reportingLog: ReportingLog): {
  priceItemValues: Map<string, number>;
  productValues: Map<string, number>;
} {
  const priceItemValues = new Map<string, number>();
  const productValues = new Map<string, number>();
  for (const entry of Object.values(reportingLog)) {
    if (entry.type !== "pickup" && entry.type !== "delivery") {
      continue;
    }
    if (entry.priceItemUses) {
      const entryType = entry.type;
      for (const priceItemUse of Object.values(entry.priceItemUses)) {
        const {count, priceItem} = priceItemUse;
        if (count == null) {
          continue;
        }
        const change = entryType === "pickup" ? count : -count;
        priceItemValues.set(priceItem, (priceItemValues.get(priceItem) || 0) + change);
      }
    }
    if (entry.productUses) {
      const entryType = entry.type;
      for (const productUse of Object.values(entry.productUses)) {
        const {count, product} = productUse;
        if (count == null) {
          continue;
        }
        const change = entryType === "pickup" ? count : -count;
        productValues.set(product, (productValues.get(product) || 0) + change);
      }
    }
  }
  return {priceItemValues, productValues};
}

function hasCopyValueFromFirstValidPickup(
  inputSpecification: ReportingInputSpecification,
): inputSpecification is ReportingInputSpecification & {
  readonly copyValueFromFirstValidPickup: string;
} {
  return inputSpecification.copyValueFromFirstValidPickup !== undefined;
}

function valuesFromFirstPickupAfterLastDelivery(
  reportingLog: ReportingLog,
  logSpecification: ReportingSpecification,
): Map<string, unknown> {
  const copiedValues = new Map<string, unknown>();

  const deliveryInputSpecifications =
    logSpecification.workplaceData.delivery && logSpecification.workplaceData.delivery.logInputs;

  if (deliveryInputSpecifications) {
    const inputsShouldHaveCopy = deliveryInputSpecifications.filter(
      hasCopyValueFromFirstValidPickup,
    );
    if (inputsShouldHaveCopy.length) {
      const sortedData = _.sortBy(Object.values(reportingLog), (entry) => entry.deviceTimestamp);
      const lastDeliveryIndex = _.findLastIndex(sortedData, (entry) => entry.type === "delivery");
      const firstPickupAfterLastDelivery = _.find(
        sortedData,
        (entry) => entry.type === "pickup",
        lastDeliveryIndex > -1 ? lastDeliveryIndex : 0,
      );
      if (firstPickupAfterLastDelivery) {
        inputsShouldHaveCopy.forEach((inputSpecification) => {
          const valueToCopy =
            firstPickupAfterLastDelivery.values[inputSpecification.copyValueFromFirstValidPickup];
          if (valueToCopy !== undefined) {
            copiedValues.set(inputSpecification.identifier, valueToCopy);
          }
        });
      }
    }
  }

  return copiedValues;
}

function hasCopyValueFromLastDelivery(
  inputSpecification: ReportingInputSpecification,
): inputSpecification is ReportingInputSpecification & {
  readonly copyValueFromLastDelivery: string;
} {
  return inputSpecification.copyValueFromLastDelivery !== undefined;
}

function valuesFromLastDelivery(
  reportingLog: ReportingLog,
  logSpecification: ReportingSpecification,
): Map<string, unknown> {
  const copiedValues = new Map<string, unknown>();

  const deliveryInputSpecifications =
    logSpecification.workplaceData.delivery && logSpecification.workplaceData.delivery.logInputs;

  if (deliveryInputSpecifications) {
    const inputsShouldHaveCopy = deliveryInputSpecifications.filter(hasCopyValueFromLastDelivery);
    if (inputsShouldHaveCopy.length) {
      const sortedData = _.sortBy(Object.values(reportingLog), (entry) => entry.deviceTimestamp);
      const lastDelivery = _.findLast(sortedData, (entry) => entry.type === "delivery");

      if (lastDelivery) {
        inputsShouldHaveCopy.forEach((inputSpecification) => {
          const valueToCopy = lastDelivery.values[inputSpecification.copyValueFromLastDelivery];
          if (valueToCopy !== undefined) {
            copiedValues.set(inputSpecification.identifier, valueToCopy);
          }
        });
      }
    }
  }

  return copiedValues;
}

function valuesFromPickupDeliveryDifference(
  reportingLog: ReportingLog,
  logSpecification: ReportingSpecification,
): Map<string, unknown> {
  const sumDifferenceValues = new Map<string, number>();

  const pickupInputSpecifications =
    logSpecification.workplaceData.pickup && logSpecification.workplaceData.pickup.logInputs;
  const deliveryInputSpecifications =
    logSpecification.workplaceData.delivery && logSpecification.workplaceData.delivery.logInputs;

  if (pickupInputSpecifications && deliveryInputSpecifications) {
    const pickupNumericInputIdentifiers = new Set(
      pickupInputSpecifications
        .filter((inputSpecification) => {
          const inputType = inputSpecification?.format?.type;
          return inputType === "integer" || inputType === "decimal";
        })
        .map((inputSpecification) => inputSpecification.identifier),
    );
    const deliveryNumericInputIdentifiers = new Set(
      deliveryInputSpecifications
        .filter((inputSpecification) => {
          const inputType = inputSpecification?.format?.type;
          return inputType === "integer" || inputType === "decimal";
        })
        .map((inputSpecification) => inputSpecification.identifier),
    );
    const commonNumericInputIdentifiers = setIntersection(
      pickupNumericInputIdentifiers,
      deliveryNumericInputIdentifiers,
    );
    if (commonNumericInputIdentifiers.size) {
      for (const identifier of commonNumericInputIdentifiers) {
        sumDifferenceValues.set(identifier, 0);
      }
      for (const entry of Object.values(reportingLog)) {
        if (!entry.values) {
          continue;
        }
        const {type: entryType} = entry;
        for (const [id, value] of Object.entries(entry.values)) {
          if (value && typeof value === "number" && commonNumericInputIdentifiers.has(id)) {
            const currentAccumulated = sumDifferenceValues.get(id) as number;
            console.assert(currentAccumulated !== undefined);
            if (entryType === "pickup") {
              sumDifferenceValues.set(id, currentAccumulated + value);
            } else if (entryType === "delivery") {
              sumDifferenceValues.set(id, currentAccumulated - value);
            }
          }
        }
      }
    }
  }

  return sumDifferenceValues;
}

function valuesFromCopyOrDifference(
  reportingLog: ReportingLog,
  logSpecification: ReportingSpecification,
): Map<string, unknown> {
  const sumDifferenceValues = valuesFromPickupDeliveryDifference(reportingLog, logSpecification);
  const copiedValuesFromFirstPickupAfterLastDelivery = valuesFromFirstPickupAfterLastDelivery(
    reportingLog,
    logSpecification,
  );
  const copiedValuesFromLastDelivery = valuesFromLastDelivery(reportingLog, logSpecification);

  if (
    (copiedValuesFromFirstPickupAfterLastDelivery.size || copiedValuesFromLastDelivery.size) &&
    sumDifferenceValues.size
  ) {
    // if some identifier is in copiedValues* maps and sumDifferenceValues
    // (should not happen; wrong configuration);
    // then value from sumDifferenceValues overwrites to match behavior
    // of old implementation
    return new Map(
      Array.from(copiedValuesFromLastDelivery.entries()).concat(
        Array.from(copiedValuesFromFirstPickupAfterLastDelivery.entries()),
        Array.from(sumDifferenceValues.entries()),
      ),
    );
  } else if (
    copiedValuesFromFirstPickupAfterLastDelivery.size &&
    copiedValuesFromLastDelivery.size
  ) {
    // if some identifier is in both copiedValues* maps
    // (should not happen; wrong configuration);
    // then value from copiedValuesFromFirstPickupAfterLastDelivery overwrites
    return new Map(
      Array.from(copiedValuesFromLastDelivery.entries()).concat(
        Array.from(copiedValuesFromFirstPickupAfterLastDelivery.entries()),
      ),
    );
  } else if (copiedValuesFromLastDelivery.size) {
    return copiedValuesFromLastDelivery;
  } else if (copiedValuesFromFirstPickupAfterLastDelivery.size) {
    return copiedValuesFromFirstPickupAfterLastDelivery;
  } else {
    // either there's only entries in sumDifferenceValues, or in neither
    return sumDifferenceValues;
  }
}

function makeLogEntryProductUsesPatch(
  oldProductUses: ProductUsesDict | undefined,
  newProductUses: ProductUsesDict,
  editingIdentifier: string,
): PathPatchOperation[] {
  const taskMemberProductUsesPatch = patchFromProductUsesChange(
    oldProductUses || {},
    newProductUses,
  );
  const patch = taskMemberProductUsesPatch.map(({path, value}) => ({
    path: ["reportingLog", editingIdentifier, ...path],
    value,
  }));
  if (!oldProductUses) {
    patch.unshift({
      path: ["reportingLog", editingIdentifier, "productUses"],
      value: {},
    });
  }
  return patch;
}

function makeLogEntryPriceItemUsesPatch(
  oldPriceItemUses: PriceItemUsesDict | undefined,
  newPriceItemUses: PriceItemUsesDict,
  editingIdentifier: string,
): PathPatchOperation[] {
  const taskMemberPriceItemUsesPatch = patchFromPriceItemUsesChange(
    oldPriceItemUses || {},
    newPriceItemUses,
  );
  const patch = taskMemberPriceItemUsesPatch.map(({path, value}) => ({
    path: ["reportingLog", editingIdentifier, ...path],
    value,
  }));
  if (!oldPriceItemUses) {
    patch.unshift({
      path: ["reportingLog", editingIdentifier, "priceItemUses"],
      value: {},
    });
  }
  return patch;
}

function makeLogEntryValuesPatch(
  oldValues: {readonly [identifier: string]: unknown} | undefined,
  newValues: {readonly [identifier: string]: unknown},
  editingIdentifier: string,
): PathPatchOperation[] {
  const patch: PathPatchOperation[] = [];
  Object.entries(newValues).forEach(([identifier, value]) => {
    if (value !== undefined && (!oldValues || value !== oldValues[identifier])) {
      // added/changed
      patch.push({
        path: ["reportingLog", editingIdentifier, "values", identifier],
        value,
      });
    }
  });
  if (oldValues) {
    Object.keys(oldValues).forEach((identifier) => {
      if (newValues[identifier] === undefined) {
        // removed
        patch.push({
          path: ["reportingLog", editingIdentifier, "values", identifier],
          value: undefined,
        });
      }
    });
  } else {
    patch.unshift({
      path: ["reportingLog", editingIdentifier, "values"],
      value: {},
    });
  }
  return patch;
}

interface LogEntryDialogProps {
  editingIdentifier: string | null;
  fieldNotesPerLocation: Map<string, [string | undefined, string | undefined]> | null;
  locationIdentifier: string | null;
  logSpecification: ReportingSpecification;
  onClose: () => void;
  onRequestDeleteEntry: () => void;
  onRequestEditEntryLocation: () => void;
  open: boolean;
  task: Task;
  timerMinutesMap: ReadonlyMap<TimerUrl, number>;
  type: "delivery" | "pickup" | "workplace" | null;
}

export const LogEntryDialog = React.memo(function LogEntryDialog(
  props: LogEntryDialogProps,
): JSX.Element {
  const {
    editingIdentifier,
    fieldNotesPerLocation,
    locationIdentifier: locationIdentifierFromProps,
    logSpecification,
    onClose,
    onRequestDeleteEntry,
    onRequestEditEntryLocation,
    open,
    task,
    timerMinutesMap,
    type: typeFromProps,
  } = props;

  const contactLookup = useSelector(getContactLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const locationLookup = useSelector(getLocationLookup);
  const machineLookup = useSelector(getMachineLookup);
  const orderLookup = useSelector(getOrderLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const projectLookup = useSelector(getProjectLookup);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
  const timerLookup = useSelector(getTimerLookup);
  const unitLookup = useSelector(getUnitLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const contactArray = useSelector(getContactArray);
  const machineArray = useSelector(getMachineArray);
  const priceItemArray = useSelector(getPriceItemArray);
  const customerSettings = useSelector(getCustomerSettings);

  const dispatch = useDispatch();

  const intl = useIntl();
  const {reportingLog} = task;

  const editingReportingEntry =
    editingIdentifier && reportingLog ? reportingLog[editingIdentifier] : undefined;

  const type = editingReportingEntry?.type || typeFromProps;

  const locationIdentifier = editingReportingEntry?.location || locationIdentifierFromProps;

  let title: string | undefined;
  if (type === "workplace") {
    title = intl.formatMessage({defaultMessage: "Registrering"});
  } else if (type === "pickup") {
    title = intl.formatMessage({defaultMessage: "Afhentning"});
  } else if (type === "delivery") {
    title = intl.formatMessage({defaultMessage: "Levering"});
  }

  const reportingLocation =
    locationIdentifier && task.reportingLocations
      ? task.reportingLocations[locationIdentifier]
      : undefined;

  const locationUrl = reportingLocation?.location;

  const inputSpecifications =
    logSpecification.workplaceData && reportingLocation?.type
      ? logSpecification.workplaceData[reportingLocation.type]?.logInputs
      : undefined;

  const workplaceValues = useMemo(
    () => reportingLocation?.values || {},
    [reportingLocation?.values],
  );

  const inputSpecificationsMap = useMemo(
    () => getInputSpecificationsMap(logSpecification),
    [logSpecification],
  );

  const entryValuesFromLocationValues = useMemo((): {
    readonly [identifier: string]: unknown;
  } => {
    const copyValues: {
      [identifier: string]: unknown;
    } = {};
    inputSpecifications?.forEach((inputSpecification) => {
      if (workplaceValues[inputSpecification.identifier] !== undefined) {
        copyValues[inputSpecification.identifier] = workplaceValues[inputSpecification.identifier];
      }
    });
    return copyValues;
  }, [inputSpecifications, workplaceValues]);

  const {priceItemValuesFromPrevious, productValuesFromPrevious, valuesFromPrevious} =
    useMemo(() => {
      let priceItemValues: Map<string, number> | undefined;
      let productValues: Map<string, number> | undefined;
      let values: Map<string, unknown> | undefined;
      if (
        ((type === "pickup" && logSpecification.autoInsertLastPickupValues) ||
          (type === "workplace" && logSpecification.autoInsertLastWorkplaceValues)) &&
        reportingLog
      ) {
        ({priceItemValues, productValues} = priceItemProductValuesFromLastLogEntry(
          reportingLog,
          type,
        ));
      } else if (
        type === "workplace" &&
        logSpecification.autoInsertLastWorkplaceConversionValue &&
        reportingLog
      ) {
        const workplaceData = logSpecification.workplaceData["workplace"];
        if (workplaceData) {
          ({priceItemValues, productValues} = priceItemProductValuesFromRelatedCountValues(
            reportingLog,
            customerSettings,
            priceItemLookup,
            productLookup,
            unitLookup,
            workplaceData,
            inputSpecificationsMap,
            task.reportingLocations,
            [editingReportingEntry?.values || {}, workplaceValues],
          ));
        }
      } else if (
        type === "delivery" &&
        logSpecification.autoInsertAmountInDelivery !== false &&
        reportingLog
      ) {
        ({priceItemValues, productValues} =
          priceItemProductValuesFromPickupDeliveryDifference(reportingLog));

        values = valuesFromCopyOrDifference(reportingLog, logSpecification);
      }
      return {
        priceItemValuesFromPrevious: priceItemValues?.size ? priceItemValues : undefined,
        productValuesFromPrevious: productValues?.size ? productValues : undefined,
        valuesFromPrevious: values?.size ? values : undefined,
      };
    }, [
      customerSettings,
      editingReportingEntry?.values,
      inputSpecificationsMap,
      logSpecification,
      priceItemLookup,
      productLookup,
      reportingLog,
      task.reportingLocations,
      type,
      unitLookup,
      workplaceValues,
    ]);

  const valuesInitial = useMemo(
    () =>
      editingReportingEntry?.values ||
      (valuesFromPrevious
        ? Object.fromEntries(valuesFromPrevious.entries())
        : entryValuesFromLocationValues),
    [editingReportingEntry?.values, entryValuesFromLocationValues, valuesFromPrevious],
  );

  const [values, dispatchValues] = useReducer(valuesReducer, valuesInitial);

  const valueMaps = useMemo(() => [values, workplaceValues], [values, workplaceValues]);

  const [priceItemUses, dispatchPriceItemUses] = useReducer(
    priceItemUsesReducer,
    getInitialOrUpdatedPriceItemUses(
      task,
      editingReportingEntry,
      priceItemValuesFromPrevious,
      priceItemLookup,
      unitLookup,
    ),
  );

  const [productUses, dispatchProductsUses] = useReducer(
    productUsesReducer,
    getInitialOrUpdatedProductUses(
      task,
      editingReportingEntry,
      productValuesFromPrevious,
      productLookup,
    ),
  );

  const readonlyProducts = useMemo(
    (): Set<ProductUrl> =>
      getReadonlyProductsFromTask(task, productLookup, unitLookup, reportingSpecificationLookup),
    [productLookup, reportingSpecificationLookup, task, unitLookup],
  );

  const productsWithLogData = useMemo(
    () => (!task.logSkipped ? getProductsWithLogData(task) : undefined),
    [task],
  );

  useEffect(() => {
    const priceItemUrls = Object.values(priceItemUses).map(
      (priceItemUse) => priceItemUse.priceItem,
    );
    const productUrls = Object.values(productUses).map((productUse) => productUse.product);
    if (inputSpecifications) {
      inputSpecifications.forEach((inputSpecification) => {
        if (inputSpecification.transferToEntry && inputSpecification.unit) {
          const value = getValue(
            customerSettings,
            valueMaps,
            inputSpecificationsMap,
            inputSpecification.identifier,
          );
          if (typeof value === "number" || value === null) {
            const priceItemTargets = getPotentialTargetTransferPriceItemUrls(
              priceItemUrls,
              inputSpecification.unit,
              priceItemLookup,
              unitLookup,
            );
            const productTargets = getPotentialTargetTransferProductUrls(
              productUrls,
              inputSpecification.unit,
              productLookup,
              unitLookup,
            );
            if (priceItemTargets.size === 1 && productTargets.size === 0) {
              Object.entries(priceItemUses).forEach(([identifier, entry]) => {
                if (priceItemTargets.has(entry.priceItem) && entry.count !== value) {
                  dispatchPriceItemUses({
                    count: value,
                    identifier,
                    type: "set-count",
                  });
                }
              });
            } else if (productTargets.size === 1 && priceItemTargets.size === 0) {
              Object.entries(productUses).forEach(([identifier, entry]) => {
                if (productTargets.has(entry.product) && entry.count !== value) {
                  dispatchProductsUses({
                    count: value,
                    identifier,
                    type: "set-count",
                  });
                }
              });
            }
          }
        }
      });
    }
  }, [
    customerSettings,
    inputSpecifications,
    inputSpecificationsMap,
    priceItemLookup,
    priceItemUses,
    productLookup,
    productUses,
    unitLookup,
    valueMaps,
  ]);

  const [date, setDate] = useState(() =>
    editingReportingEntry ? dateToString(new Date(editingReportingEntry.deviceTimestamp)) : null,
  );
  const [time, setTime] = useState(() =>
    editingReportingEntry ? convertTimestampToTime(editingReportingEntry.deviceTimestamp) : null,
  );

  useEffect(() => {
    if (open) {
      dispatchValues({
        type: "replace",
        value:
          editingReportingEntry?.values ||
          (valuesFromPrevious
            ? Object.fromEntries(valuesFromPrevious.entries())
            : entryValuesFromLocationValues),
      });
      dispatchPriceItemUses({
        type: "replace",
        value: getInitialOrUpdatedPriceItemUses(
          task,
          editingReportingEntry,
          priceItemValuesFromPrevious,
          priceItemLookup,
          unitLookup,
        ),
      });
      dispatchProductsUses({
        type: "replace",
        value: getInitialOrUpdatedProductUses(
          task,
          editingReportingEntry,
          productValuesFromPrevious,
          productLookup,
        ),
      });
      setDate(
        editingReportingEntry
          ? dateToString(new Date(editingReportingEntry.deviceTimestamp))
          : null,
      );
      setTime(
        editingReportingEntry
          ? convertTimestampToTime(editingReportingEntry.deviceTimestamp)
          : null,
      );
    }
    // Avoid reinitializing when editing gets new identity due to other log patch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const sortedPriceItemUses = useMemo(
    (): readonly {
      readonly identifier: string;
      readonly priceItemUse: PriceItemUseWithOrder;
    }[] =>
      _.sortBy(
        Object.entries(priceItemUses).map(([identifier, priceItemUse]) => ({
          identifier,
          priceItemUse,
        })),
        ({priceItemUse}) => priceItemUse.order,
      ),
    [priceItemUses],
  );

  const sortedProductUses = useMemo(
    (): readonly {
      readonly identifier: string;
      readonly productUse: ProductUseWithOrder;
    }[] =>
      _.sortBy(
        Object.entries(productUses).map(([identifier, productUse]) => ({
          identifier,
          productUse,
        })),
        ({productUse}) => productUse.order,
      ),
    [productUses],
  );

  const priceItemUseList = useMemo(
    (): readonly PriceItemUseWithOrder[] =>
      sortedPriceItemUses.map(({priceItemUse}) => priceItemUse),
    [sortedPriceItemUses],
  );
  const productUseList = useMemo(
    (): readonly ProductUseWithOrder[] => sortedProductUses.map(({productUse}) => productUse),
    [sortedProductUses],
  );

  const handleFieldChange = useCallback((identifier: string, value: unknown): void => {
    dispatchValues({identifier, type: "put", value});
  }, []);

  const handleTransferOk = useCallback(
    (value: number | null, url: string, unit: string): void => {
      const priceItemTargets = getPotentialTargetTransferPriceItemUrls(
        priceItemUseList.map((priceItemUse) => priceItemUse.priceItem),
        unit,
        priceItemLookup,
        unitLookup,
      );
      const productTargets = getPotentialTargetTransferProductUrls(
        productUseList.map((productUse) => productUse.product),
        unit,
        productLookup,
        unitLookup,
      );
      Object.entries(priceItemUses).forEach(([identifier, priceItemUse]) => {
        if (priceItemTargets.has(priceItemUse.priceItem)) {
          if (priceItemUse.priceItem === url) {
            dispatchPriceItemUses({
              count: value,
              identifier,
              type: "set-count",
            });
          } else {
            dispatchPriceItemUses({count: null, identifier, type: "set-count"});
          }
        }
      });
      Object.entries(productUses).forEach(([identifier, productUse]) => {
        if (productTargets.has(productUse.product)) {
          if (productUse.product === url) {
            dispatchProductsUses({count: value, identifier, type: "set-count"});
          } else {
            dispatchProductsUses({count: null, identifier, type: "set-count"});
          }
        }
      });
    },
    [
      priceItemLookup,
      priceItemUseList,
      priceItemUses,
      productLookup,
      productUseList,
      productUses,
      unitLookup,
    ],
  );

  const {
    anyRequired,
    inputFields,
    okDisabled: initialOkDisabled,
  } = buildDialogFields(
    unitLookup,
    inputSpecifications || [],
    customerSettings,
    valueMaps,
    inputSpecificationsMap,
    handleFieldChange,
    values,
    priceItemUseList,
    productUseList,
    handleTransferOk,
  );

  const workplaceTypeDataSpecification = useMemo(
    () => (type ? logSpecification.workplaceData[type] : undefined),
    [logSpecification.workplaceData, type],
  );

  let okDisabled = initialOkDisabled;

  const includeProducts = !!workplaceTypeDataSpecification?.logProducts;
  const includePriceItems = !!workplaceTypeDataSpecification?.logPriceItems;

  const requireAtLeastOneProduct = !!workplaceTypeDataSpecification?.requireAtLeastOneProduct;

  let fieldNotesBlock: JSX.Element | undefined;
  if (locationUrl && fieldNotesPerLocation) {
    const fieldNotes = fieldNotesPerLocation.get(locationUrl);
    if (fieldNotes) {
      const [taskFieldNote, orderFieldNote] = fieldNotes;
      if (taskFieldNote || orderFieldNote) {
        fieldNotesBlock = (
          <div>
            <div>
              <FormattedMessage defaultMessage="Marknoter:" />
            </div>
            {taskFieldNote}
            {taskFieldNote ? <br /> : null}
            {orderFieldNote}
          </div>
        );
      }
    }
  }

  let requiredNote: JSX.Element | undefined;
  if (anyRequired) {
    requiredNote = (
      <div style={{width: "100%"}}>
        <FormattedMessage defaultMessage="* krævet" />
      </div>
    );
  }

  const note = reportingLocation ? reportingLocation.values?.locationNote : null;

  const location = locationUrl ? locationLookup(locationUrl) : undefined;

  let materialsBlock: JSX.Element | undefined;

  if ((includePriceItems || includeProducts) && workplaceTypeDataSpecification) {
    const materialError = wrappedGetLogIssues(priceItemUses, productUses, !!includePriceItems, {
      contactLookup,
      customerLookup,
      customerSettings,
      intl,
      machineArray,
      machineLookup,
      orderLookup,
      priceGroupLookup,
      priceItemArray,
      priceItemLookup,
      productGroupLookup,
      productLookup,
      productsWithLogData,
      projectLookup,
      readonlyProducts,
      reportingSpecificationLookup,
      task,
      timerLookup,
      unitLookup,
      workTypeLookup,
    });

    okDisabled =
      okDisabled ||
      !!materialError ||
      (includeProducts && requireAtLeastOneProduct && !productUseList.length);

    materialsBlock = (
      <MaterialsBlock
        dispatchPriceItemUses={dispatchPriceItemUses}
        dispatchProductsUses={dispatchProductsUses}
        inputSpecificationsMap={inputSpecificationsMap}
        locationUrl={locationUrl}
        materialError={materialError}
        priceItemUses={priceItemUses}
        productUses={productUses}
        task={task}
        timerMinutesMap={timerMinutesMap}
        valueMaps={valueMaps}
        workplaceTypeDataSpecification={workplaceTypeDataSpecification}
      />
    );
  }

  const handleOk = useCallback(() => {
    if (!open) {
      return;
    }
    const patch: PatchOperation<Task>[] = [];
    if (editingIdentifier && editingReportingEntry) {
      if (
        date &&
        time &&
        (date !== dateToString(new Date(editingReportingEntry.deviceTimestamp)) ||
          time !== convertTimestampToTime(editingReportingEntry.deviceTimestamp))
      ) {
        const newTimestamp = dateFromDateAndTime(date, time).toISOString();
        patch.push({
          path: ["reportingLog", editingIdentifier, "deviceTimestamp"],
          value: newTimestamp,
        });
      }

      const logEntryProductUsesPatch = makeLogEntryProductUsesPatch(
        editingReportingEntry.productUses,
        productUses,
        editingIdentifier,
      );
      patch.push(...logEntryProductUsesPatch);

      const logEntryPriceItemUsesPatch = makeLogEntryPriceItemUsesPatch(
        editingReportingEntry.priceItemUses,
        priceItemUses,
        editingIdentifier,
      );
      patch.push(...logEntryPriceItemUsesPatch);

      const logEntryValuesPatch = makeLogEntryValuesPatch(
        editingReportingEntry.values,
        values,
        editingIdentifier,
      );
      patch.push(...logEntryValuesPatch);
    } else if (locationIdentifier && type) {
      const currentMaxOrder = reportingLog
        ? _.max(Object.values(reportingLog).map((logEntry) => logEntry.order))
        : undefined;
      const nextOrder = currentMaxOrder !== undefined ? currentMaxOrder + 1 : 0;
      const logEntryIdentifier = uuid();
      const logEntry: ReportingLogEntry = {
        deviceTimestamp: new Date().toISOString(),
        location: locationIdentifier,
        order: nextOrder,
        priceItemUses,
        productUses,
        type,
        values,
      };
      patch.push({
        path: ["reportingLog", logEntryIdentifier],
        value: logEntry,
      });
    } else {
      // should not happen
      return;
    }

    if (patch.length) {
      dispatch(actions.update(task.url, patch));
    }
    if (customerSettings.geolocation.registerPositionOnTimerClick) {
      dispatch(actions.registerTaskPosition(task.url));
    }
    onClose();
  }, [
    customerSettings.geolocation.registerPositionOnTimerClick,
    date,
    dispatch,
    editingIdentifier,
    editingReportingEntry,
    locationIdentifier,
    onClose,
    open,
    priceItemUses,
    productUses,
    reportingLog,
    task.url,
    time,
    type,
    values,
  ]);

  const contentElementRef = useRef<HTMLDivElement>();

  const handleMobileKeyPress = useCallback((event: KeyboardEvent): void => {
    if (!isNoDefaultEnterPress(event)) {
      // no effect if not enter or focus on element with enter handling
      return;
    }
    const {target} = event;
    if (!target || (target as Element).nodeName !== "INPUT") {
      // focus not on something with enter-handling and somehow also not
      // on an input element -- do nothing then, just to be (type-) safe...
      return;
    }
    const contentElement = contentElementRef.current;
    if (!contentElement) {
      // we don't have the DOM element, so can't do anything
      return;
    }
    const inputElements = Array.from(
      contentElement.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>("input,textarea"),
    );
    const currentIndex = inputElements.indexOf(target as any);
    if (currentIndex === -1) {
      // in case event handling is delayed so DOM state changed...
      return;
    }
    const nextInput = inputElements.slice(currentIndex + 1).find((element) => !element.disabled);
    if (!nextInput) {
      // no further non-disabled element
      return;
    }
    event.preventDefault();
    nextInput.focus();
  }, []);

  useEffect(() => {
    if (!open || (!bowser.mobile && !bowser.tablet)) {
      return undefined;
    }
    window.addEventListener("keypress", handleMobileKeyPress);
    return () => {
      window.removeEventListener("keypress", handleMobileKeyPress);
    };
  }, [handleMobileKeyPress, open]);

  return (
    <ResponsiveDialog
      fullscreen
      enterHandling={false}
      okDisabled={okDisabled}
      okLabel={intl.formatMessage({defaultMessage: "Indmeld"})}
      open={open}
      title={title || ""}
      onCancel={onClose}
      onDelete={editingIdentifier ? onRequestDeleteEntry : undefined}
      onOk={handleOk}
    >
      <DialogContent ref={contentElementRef}>
        <Grid container>
          <Grid item lg={8} md={6} xs={12}>
            <h3>
              {location?.name || location?.address}
              {customerSettings.addEditLogLocationSkipsCustomerSelection && locationIdentifier ? (
                <EditReportingLocationFab
                  disabled={task.validatedAndRecorded}
                  logLocationId={locationIdentifier}
                  logSpecification={logSpecification}
                  task={task}
                />
              ) : (
                <IconButton onClick={onRequestEditEntryLocation}>
                  <PencilIcon />
                </IconButton>
              )}
            </h3>
            <table>
              <tbody>
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Adresse:" />
                  </td>
                  <td>
                    {`${location?.address || ""}${
                      location?.postalCode || location?.city ? "," : ""
                    } ${location?.postalCode || ""} ${location?.city || ""}`.trim()}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Kontakt:" />
                  </td>
                  <td>{location?.attention}</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Telefon:" />
                  </td>
                  <td>{getLocationPhone(location || null, contactArray, customerLookup)}</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Note:" />
                  </td>
                  <td>{typeof note === "string" ? note : null}</td>
                </tr>
              </tbody>
            </table>

            {fieldNotesBlock}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {inputFields}
            {requiredNote}
            {editingIdentifier ? (
              <>
                <DateField
                  autoOk
                  fullWidth
                  disabled={task.validatedAndRecorded}
                  label={<FormattedMessage defaultMessage="Dato" />}
                  margin="normal"
                  value={date}
                  onChange={setDate}
                />
                <TimeField
                  fullWidth
                  disabled={task.validatedAndRecorded}
                  label={<FormattedMessage defaultMessage="Tid" />}
                  margin="normal"
                  value={time ?? undefined}
                  onChange={setTime}
                />
              </>
            ) : null}
          </Grid>
        </Grid>
        {materialsBlock}
      </DialogContent>
    </ResponsiveDialog>
  );
}, _.isEqual);
