import {
  KrPerLiterFuelSurchargeSpecificationEntry,
  KrPerLiterFuelSurchargeSpecificationEntryUrl,
  KrPerLiterFuelSurchargeSpecificationUrl,
  PatchOperation,
  PricePercentFuelSurchargeSpecificationEntry,
  PricePercentFuelSurchargeSpecificationEntryUrl,
  PricePercentFuelSurchargeSpecificationUrl,
  UserUrl,
} from "@co-common-libs/resources";
import {DateField, DecimalField, ResponsiveDialog} from "@co-frontend-libs/components";
import {actions, getCurrentUserURL} from "@co-frontend-libs/redux";
import {DialogContent, Grid, useTheme} from "@material-ui/core";
import {suggestNextDateInterval} from "app-utils";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

const decimalPlaces = 2;

interface CommonFuelSurchargeSpecificationEntryDialogProps {
  conversionFactor: number;
  onClose: () => void;
  open: boolean;
}

interface KrPerLiterFuelSurchargeSpecificationEntryDialogProps
  extends CommonFuelSurchargeSpecificationEntryDialogProps {
  editingUrl: KrPerLiterFuelSurchargeSpecificationEntryUrl | undefined;
  resourceName: "krPerLiterFuelSurchargeSpecificationEntry";
  sortedExistingEntries: readonly KrPerLiterFuelSurchargeSpecificationEntry[];
  specificationEntryLookup: (
    url: KrPerLiterFuelSurchargeSpecificationEntryUrl,
  ) => KrPerLiterFuelSurchargeSpecificationEntry | undefined;
  specificationUrl: KrPerLiterFuelSurchargeSpecificationUrl;
}

interface PricePercentFuelSurchargeSpecificationEntryDialogProps
  extends CommonFuelSurchargeSpecificationEntryDialogProps {
  editingUrl: PricePercentFuelSurchargeSpecificationEntryUrl | undefined;
  resourceName: "pricePercentFuelSurchargeSpecificationEntry";
  sortedExistingEntries: readonly PricePercentFuelSurchargeSpecificationEntry[];
  specificationEntryLookup: (
    url: PricePercentFuelSurchargeSpecificationEntryUrl,
  ) => PricePercentFuelSurchargeSpecificationEntry | undefined;
  specificationUrl: PricePercentFuelSurchargeSpecificationUrl;
}

interface FuelSurchargeSpecificationEntryDialogProps
  extends CommonFuelSurchargeSpecificationEntryDialogProps {
  editingUrl:
    | KrPerLiterFuelSurchargeSpecificationEntryUrl
    | PricePercentFuelSurchargeSpecificationEntryUrl
    | undefined;
  resourceName:
    | "krPerLiterFuelSurchargeSpecificationEntry"
    | "pricePercentFuelSurchargeSpecificationEntry";
  sortedExistingEntries: readonly (
    | KrPerLiterFuelSurchargeSpecificationEntry
    | PricePercentFuelSurchargeSpecificationEntry
  )[];
  specificationEntryLookup: (
    url:
      | KrPerLiterFuelSurchargeSpecificationEntryUrl
      | PricePercentFuelSurchargeSpecificationEntryUrl,
  ) =>
    | KrPerLiterFuelSurchargeSpecificationEntry
    | PricePercentFuelSurchargeSpecificationEntry
    | undefined;
  specificationUrl:
    | KrPerLiterFuelSurchargeSpecificationUrl
    | PricePercentFuelSurchargeSpecificationUrl;
}

const FuelSurchargeSpecificationEntryDialog = React.memo(
  function FuelSurchargeSpecificationEntryDialog(
    props: FuelSurchargeSpecificationEntryDialogProps,
  ): JSX.Element {
    const {
      conversionFactor,
      editingUrl,
      onClose,
      open,
      resourceName,
      sortedExistingEntries,
      specificationEntryLookup,
      specificationUrl,
    } = props;

    const lastEntry = _.last(sortedExistingEntries);

    const currentUserUrl = useSelector(getCurrentUserURL);

    const dispatch = useDispatch();

    const editingEntry = useMemo(
      () => (editingUrl ? specificationEntryLookup(editingUrl) : null),
      [editingUrl, specificationEntryLookup],
    );

    const datesFromSuggested = useMemo(
      () => (lastEntry ? suggestNextDateInterval(lastEntry) : null),
      [lastEntry],
    );

    const intl = useIntl();

    const [fromDate, setFromDate] = useState<string | null>(
      editingUrl ? (editingEntry?.fromDate ?? null) : (datesFromSuggested?.fromDate ?? null),
    );
    const [toDate, setToDate] = useState<string | null>(
      editingUrl ? (editingEntry?.toDate ?? null) : (datesFromSuggested?.toDate ?? null),
    );

    const [datesAreFromSuggested, setDatesAreFromSuggested] = useState(!editingUrl);

    const [fromDateChanged, setFromDateChanged] = useState(false);

    const [priceKrPerFactorLiter, setPriceKrPerFactorLiter] = useState<number | null>(
      editingEntry?.priceKrPerLiter
        ? _.round(editingEntry.priceKrPerLiter * conversionFactor, decimalPlaces)
        : null,
    );

    useEffect(() => {
      if (open) {
        setFromDate(
          editingUrl ? (editingEntry?.fromDate ?? null) : (datesFromSuggested?.fromDate ?? null),
        );
        setToDate(
          editingUrl ? (editingEntry?.toDate ?? null) : (datesFromSuggested?.toDate ?? null),
        );
        setDatesAreFromSuggested(!editingUrl);
        setFromDateChanged(false);
        setPriceKrPerFactorLiter(
          editingEntry?.priceKrPerLiter
            ? _.round(editingEntry.priceKrPerLiter * conversionFactor, decimalPlaces)
            : null,
        );
      }
    }, [
      conversionFactor,
      datesFromSuggested?.fromDate,
      datesFromSuggested?.toDate,
      editingEntry,
      editingUrl,
      open,
    ]);

    const handleFromDateChange = useCallback((value: string | null): void => {
      setFromDate(value);
      setFromDateChanged(true);
    }, []);

    const handleToDateChange = useCallback((value: string | null): void => {
      setToDate(value);
      setDatesAreFromSuggested(false);
    }, []);

    const handleFromDateBlur = useCallback((): void => {
      if (
        (datesAreFromSuggested && fromDateChanged) ||
        (fromDate && (!toDate || fromDate > toDate))
      ) {
        setToDate(fromDate);
        setDatesAreFromSuggested(false);
      }
    }, [datesAreFromSuggested, fromDate, fromDateChanged, toDate]);

    const handlePeriodStartSelectedInDialog = useCallback(
      (date: string | null): void => {
        if (date) {
          setFromDate(date);
          if (datesAreFromSuggested || !toDate || date > toDate) {
            setToDate(date);
          }
        }
        setDatesAreFromSuggested(false);
      },
      [datesAreFromSuggested, toDate],
    );

    const overlapsExistingIssue = useMemo((): boolean => {
      if (editingUrl) {
        return false;
      }
      if (!fromDate || !toDate) {
        return false;
      }
      return !sortedExistingEntries.every((entry) => {
        // easiest to express in terms of when there is *no* overlap
        return fromDate > entry.toDate || toDate < entry.fromDate;
      });
    }, [editingUrl, fromDate, sortedExistingEntries, toDate]);

    const okDisabled =
      !fromDate ||
      !toDate ||
      fromDate > toDate ||
      priceKrPerFactorLiter === null ||
      overlapsExistingIssue;

    const maxDigits = conversionFactor === 1 ? 5 : 8;

    const handleOk = useCallback((): void => {
      if (!fromDate || !toDate || priceKrPerFactorLiter === null) {
        return;
      }
      if (resourceName === "pricePercentFuelSurchargeSpecificationEntry") {
        if (editingUrl) {
          const patch: PatchOperation<PricePercentFuelSurchargeSpecificationEntry>[] = [];
          if (priceKrPerFactorLiter !== editingEntry?.priceKrPerLiter) {
            patch.push({
              member: "priceKrPerLiter",
              value: priceKrPerFactorLiter / conversionFactor,
            });
          }
          if (toDate !== editingEntry?.toDate) {
            patch.push({
              member: "toDate",
              value: toDate,
            });
          }
          if (patch.length) {
            patch.push({member: "changedBy", value: currentUserUrl as UserUrl});
            dispatch(actions.update(editingUrl, patch));
          }
        } else if (!editingUrl) {
          const id = uuid();
          const url = instanceURL(resourceName, id);
          const instance: PricePercentFuelSurchargeSpecificationEntry = {
            changedBy: currentUserUrl as UserUrl,
            fromDate,
            id,
            priceKrPerLiter: priceKrPerFactorLiter / conversionFactor,
            specification: specificationUrl as PricePercentFuelSurchargeSpecificationUrl,
            toDate,
            url,
          };
          dispatch(actions.create(instance));
        }
      } else if (resourceName === "krPerLiterFuelSurchargeSpecificationEntry") {
        if (editingUrl) {
          const patch: PatchOperation<KrPerLiterFuelSurchargeSpecificationEntry>[] = [];
          if (priceKrPerFactorLiter !== editingEntry?.priceKrPerLiter) {
            patch.push({
              member: "priceKrPerLiter",
              value: priceKrPerFactorLiter / conversionFactor,
            });
          }
          if (toDate !== editingEntry?.toDate) {
            patch.push({
              member: "toDate",
              value: toDate,
            });
          }
          if (patch.length) {
            patch.push({member: "changedBy", value: currentUserUrl as UserUrl});
            dispatch(actions.update(editingUrl, patch));
          }
        } else if (!editingUrl) {
          const id = uuid();
          const url = instanceURL(resourceName, id);
          const instance: KrPerLiterFuelSurchargeSpecificationEntry = {
            changedBy: currentUserUrl as UserUrl,
            fromDate,
            id,
            priceKrPerLiter: priceKrPerFactorLiter / conversionFactor,
            specification: specificationUrl as KrPerLiterFuelSurchargeSpecificationUrl,
            toDate,
            url,
          };
          dispatch(actions.create(instance));
        }
      }
      onClose();
    }, [
      conversionFactor,
      currentUserUrl,
      dispatch,
      editingEntry?.priceKrPerLiter,
      editingEntry?.toDate,
      editingUrl,
      fromDate,
      onClose,
      priceKrPerFactorLiter,
      resourceName,
      specificationUrl,
      toDate,
    ]);

    const handleDelete = useCallback((): void => {
      if (editingUrl) {
        dispatch(actions.remove(editingUrl));
      }
      onClose();
    }, [dispatch, editingUrl, onClose]);

    const theme = useTheme();

    return (
      <ResponsiveDialog
        maxWidth="md"
        okDisabled={okDisabled}
        open={open}
        title={
          editingUrl
            ? intl.formatMessage({defaultMessage: "Redigér periode"})
            : intl.formatMessage({defaultMessage: "Opret periode"})
        }
        onCancel={onClose}
        onDelete={editingUrl ? handleDelete : undefined}
        onOk={handleOk}
      >
        <DialogContent style={{overflow: "visible"}}>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <DateField
                autoFocus
                autoOk
                fullWidth
                disabled={!!editingUrl}
                label={intl.formatMessage({defaultMessage: "Fra dato"})}
                margin="dense"
                value={fromDate}
                onBlur={handleFromDateBlur}
                onChange={handleFromDateChange}
                onSelectedInDialog={handlePeriodStartSelectedInDialog}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <DateField
                autoOk
                fullWidth
                disabled={!!editingUrl && editingUrl !== lastEntry?.url}
                label={intl.formatMessage({defaultMessage: "Til dato"})}
                margin="dense"
                minDate={fromDate}
                value={toDate}
                onChange={handleToDateChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <DecimalField
                fullWidth
                decimalPlaces={decimalPlaces}
                label={
                  conversionFactor === 1
                    ? intl.formatMessage({
                        defaultMessage: "Pris (kr/L)",
                      })
                    : intl.formatMessage(
                        {
                          defaultMessage: "Pris (kr/{conversionFactor, number} L)",
                        },
                        {conversionFactor},
                      )
                }
                margin="dense"
                maxDigits={maxDigits}
                value={priceKrPerFactorLiter}
                onChange={setPriceKrPerFactorLiter}
              />
            </Grid>
            {overlapsExistingIssue ? (
              <Grid item xs={12}>
                <div style={{color: theme.palette.error.main}}>
                  <FormattedMessage defaultMessage="Overlapper eksisterende periode" />
                </div>
              </Grid>
            ) : null}
            {!overlapsExistingIssue && fromDate && toDate && fromDate > toDate ? (
              <Grid item xs={12}>
                <div style={{color: theme.palette.error.main}}>
                  <FormattedMessage defaultMessage="Den angivne til dato ligger inden fra dato" />
                </div>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
      </ResponsiveDialog>
    );
  },
);

export const KrPerLiterFuelSurchargeSpecificationEntryDialog: React.FC<KrPerLiterFuelSurchargeSpecificationEntryDialogProps> =
  FuelSurchargeSpecificationEntryDialog as any;

export const PricePercentFuelSurchargeSpecificationEntryDialog: React.FC<PricePercentFuelSurchargeSpecificationEntryDialogProps> =
  FuelSurchargeSpecificationEntryDialog as any;
