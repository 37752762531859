import {
  AnniversaryType,
  Task,
  TimerStart,
  User,
  UserProfile,
  availabilityWeekdayFromDate,
} from "@co-common-libs/resources";
import {dateFromString} from "@co-common-libs/utils";
import {
  getContactLookup,
  getCultureLookup,
  getCustomerLookup,
  getCustomerSettings,
  getDateEmployeeAvailabilityMap,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPriceItemLookup,
  getProductLookup,
  getProjectLookup,
  getTimerLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  TaskWithRelations,
  generateTimerStartMapping,
  resolveTaskRelations,
  sortTasksInColumns,
} from "app-components";
import {computeIntervalsTruncated} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {UserHeader} from "./user-header";
import {UserHeaderMenu} from "./user-header-menu";

interface UsersHeaderBlockOwnProps {
  dndMode: boolean;
  fromTimestamp: ImmutableDate;
  incompleteTasks: readonly Task[];
  intersectingCompletedTasks: readonly Task[];
  intersectingPlannedTasks: readonly Task[];
  onRequestOrdering: (ordering: readonly string[]) => void;
  ordering?: readonly string[] | undefined;
  selectedDateString: string;
  sortedTimerStarts: readonly TimerStart[];
  toTimestamp: ImmutableDate;
  userAnniversaryMap: ReadonlyMap<string, AnniversaryType[]> | null;
  usersWithAccommodationAllowanceList: readonly string[];
  usersWithTasksToday: readonly User[];
  visibleUsersWithTasksToday: readonly User[];
}

export const UsersHeaderBlock = React.memo(function UsersHeaderBlock(
  props: UsersHeaderBlockOwnProps,
): JSX.Element {
  const {
    dndMode,
    fromTimestamp,
    incompleteTasks,
    intersectingCompletedTasks,
    intersectingPlannedTasks,
    onRequestOrdering,
    ordering,
    selectedDateString,
    sortedTimerStarts,
    toTimestamp,
    userAnniversaryMap,
    usersWithAccommodationAllowanceList,
    usersWithTasksToday,
    visibleUsersWithTasksToday,
  } = props;

  const contactLookup = useSelector(getContactLookup);
  const cultureLookup = useSelector(getCultureLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const locationLookup = useSelector(getLocationLookup);
  const machineLookup = useSelector(getMachineLookup);
  const orderLookup = useSelector(getOrderLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const projectLookup = useSelector(getProjectLookup);
  const timerLookup = useSelector(getTimerLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const [userData, setUserData] = useState<{
    user: Readonly<User>;
    userProfile: Readonly<UserProfile>;
  } | null>(null);
  const [userHeaderMenuAnchorElement, setUserHeaderMenuAnchorElement] =
    useState<HTMLElement | null>();
  const [userHeaderMenuOpen, setUserHeaderMenuOpen] = useState(false);
  const dateEmployeeAvailabilityMap = useSelector(
    getDateEmployeeAvailabilityMap(selectedDateString),
  );
  const selectedDate = dateFromString(selectedDateString);

  const handleEmployeeDragDrop = useCallback(
    (moved: string, target: string) => {
      if (moved === target) {
        return;
      }
      const usersInitials = usersWithTasksToday.map(
        (u) => userUserProfileLookup(u.url)?.alias || "",
      );

      const oldOrdering = ordering;
      let baseOrdering;
      if (oldOrdering) {
        baseOrdering = oldOrdering.concat(
          usersInitials.filter((initials) => !oldOrdering.includes(initials)),
        );
      } else {
        baseOrdering = usersInitials;
      }
      const movedIndex = baseOrdering.indexOf(moved);
      const targetIndex = baseOrdering.indexOf(target);
      if (movedIndex === -1 || targetIndex === -1) {
        return;
      }
      // When moving to the left, we want moved to be put to the left of target;
      // when moving to the right, we want moved to be put to the right of target.
      // The "obvious" implementation of both turns out to be the same line:
      // When moving to the left, the delete *does not* shift target, but the insert does.
      // When moving to the right, the delete *does* shift target, after which the insert does not.
      const result = [...baseOrdering];
      result.splice(movedIndex, 1);
      result.splice(targetIndex, 0, moved);
      onRequestOrdering(result);
    },
    [onRequestOrdering, ordering, userUserProfileLookup, usersWithTasksToday],
  );

  const now = new Date();
  const nowString = now.toISOString();
  const taskTimerStartMapping = generateTimerStartMapping(sortedTimerStarts);

  const boundResolveTaskRelations = (task: Task): TaskWithRelations => {
    let taskWithComputedTimeSet = task;
    if (!task.archivable) {
      const taskURL = task.url;
      const arrayForTask = taskTimerStartMapping[taskURL];
      const computedIntervals = computeIntervalsTruncated(arrayForTask || [], now.toISOString());
      taskWithComputedTimeSet = {...task, computedTimeSet: computedIntervals};
    }
    return resolveTaskRelations(
      taskWithComputedTimeSet,
      fromTimestamp,
      toTimestamp,
      nowString,
      false,
      customerSettings,
      {
        contactLookup,
        cultureLookup,
        customerLookup,
        locationLookup,
        machineLookup,
        orderLookup,
        priceItemLookup,
        productLookup,
        projectLookup,
        timerLookup,
        workTypeLookup,
      },
    );
  };

  const handleUserHeaderClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, user: User, userProfile: UserProfile) => {
      setUserData({user, userProfile});
      setUserHeaderMenuAnchorElement(event.currentTarget);
      setUserHeaderMenuOpen(true);
    },
    [],
  );
  const userEntries = visibleUsersWithTasksToday.map((user) => {
    const userUrl = user.url;
    const columns = sortTasksInColumns(
      userUrl,
      intersectingPlannedTasks,
      intersectingCompletedTasks,
      incompleteTasks,
      boundResolveTaskRelations,
      customerSettings.taskOverlapWarningAfterMinutes,
    );
    const columnCount = columns.length;
    const userAvailability = dateEmployeeAvailabilityMap.get(userUrl);
    const userProfile = userUserProfileLookup(userUrl);
    return (
      <UserHeader
        key={userUrl}
        anniversaries={userAnniversaryMap?.get(userUrl)}
        availability={
          selectedDate && userAvailability
            ? (userAvailability[availabilityWeekdayFromDate(selectedDate)] ?? undefined)
            : undefined
        }
        columnCount={columnCount}
        customerSettings={customerSettings}
        date={fromTimestamp}
        dndMode={dndMode}
        hasAccommodationAllowance={usersWithAccommodationAllowanceList.includes(userUrl)}
        user={user}
        userProfile={userProfile}
        onClick={handleUserHeaderClick}
        onDragDrop={handleEmployeeDragDrop}
      />
    );
  });

  const handleMenuClose = useCallback((): void => {
    setUserHeaderMenuOpen(false);
  }, []);

  const userHeaderMenu =
    selectedDate && userData && userHeaderMenuAnchorElement ? (
      <UserHeaderMenu
        anchorElement={userHeaderMenuAnchorElement}
        date={selectedDate}
        open={userHeaderMenuOpen}
        user={userData.user}
        userProfile={userData.userProfile}
        onClose={handleMenuClose}
      />
    ) : null;

  const headerLineHeight = 24;
  const nameLines = 2;
  const headerLines =
    (customerSettings.calendarShowEmployeeAlias ? 1 : 0) +
    (customerSettings.calendarShowEmployeeNumber ? 1 : 0) +
    (customerSettings.calendarShowEmployeeName ? nameLines : 0);

  const usersHeaderHeight = Math.max(headerLines, 1) * headerLineHeight;

  return (
    <div id="users-header-block" style={{display: "inline-block", height: usersHeaderHeight}}>
      {userEntries}
      {userHeaderMenu}
    </div>
  );
});
